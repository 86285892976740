import React from 'react';
import Stats from './Stats';
import RecentTransactions from './RecentTransactions';

function Video() {
  return (
    <div className="relative w-full px-[28px]">
      {/* <Stats /> */}
      {/* <RecentTransactions /> */}
      <iframe
        className="block mx-auto w-full xl:h-[518px] max-h-[518px] w-[300px] xl:w-[900px] rounded-[50px] border-white border"
        src="https://player.kick.com/nemotronsol"
        title="NEMO LIVE STREAM"
        width="100%" // Ensure it's responsive to container width
        height="500" // You can adjust the height as needed
        frameBorder="0" // Optional, but good practice to remove border
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" // Allow necessary media features
        allowFullScreen // Enable fullscreen capability
      ></iframe>
    </div>
  );
}

export default Video;
