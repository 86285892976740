import React from 'react';
import Copy from '../images/Copy.jsx';
import handleCopyToClipboard from '../util/copyToClipboard.js';

function Links() {
  return (
    <div className="flex flex-col xl:flex-row gap-x-[16px] items-start px-[55px]">
      <div className="flex flex-row items-center gap-x-[16px]">
        <a
          href="www"
          className="text-white text-[20px] lg:text-[24px] underline"
        >
          pump
        </a>
        <a
          href="www"
          className="text-white text-[20px] lg:text-[24px] underline"
        >
          tg
        </a>
        <a
          href="https://twitter.com/nemotronsol"
          className="text-white text-[20px] lg:text-[24px] underline"
        >
          x
        </a>
        <a
          href="www"
          className="text-white text-[20px] lg:text-[24px] underline"
        >
          buy
        </a>
        <a
          href="www"
          className="text-white text-[20px] lg:text-[24px] underline"
        >
          screener
        </a>
      </div>
      <div className="flex flex-row items-center justify-center gap-x-[16px]">
        <a
          href="www"
          className="text-white text-[20px] lg:text-[24px] underline"
        >
          racing
        </a>
        <div
          onClick={() => handleCopyToClipboard('testCA')}
          href="www"
          className="text-white  text-[20px] lg:text-[24px] underline flex flex-row items-center justify-center gap-x-[4px]"
        >
          ca
          <Copy />
        </div>
        <div
          onClick={() => handleCopyToClipboard('testNemo')}
          className="text-white  text-[20px] lg:text-[24px] underline flex flex-row items-center justify-center gap-x-[4px]"
        >
          nemo's wallet
          <Copy />
        </div>
      </div>
    </div>
  );
}

export default Links;
