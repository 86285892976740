import { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Links from './components/Links';
import Video from './components/Video';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BoxSvg() {
  return (
    <div className="hidden xl:flex absolute top-[45%] -left-2 w-[100px] h-[100px] border-2 border-dashed border-[#fff]/40"></div>
  )
}

function BoxTwoSvg() {
  return (
    <div className="hidden xl:flex absolute top-[20%] -right-2 w-[50px] h-[50px] border-2 border-dashed border-[#fff]/40"></div>
  )
}

function App() {
  return (
    <div
      className="py-[100px] gap-y-[32px] bg-[#101010] px- w-screen h-screen bg-cover bg-center max-w-screen overflow-x-hidden flex flex-col items-center relative"
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        toastStyle={{
          backgroundColor: '#1a1a1a',
          color: '#ffffff',
          border: '1px solid rgba(255, 255, 255, 0.1)'
        }}
/>
      <BoxSvg />
      <BoxTwoSvg />
      <Header />
      <Video />
      <Links />
    </div>
  );
}
export default App;
