const Copy = () => {
  return (
    <svg
      className="mt-[8px] ml-[4px]"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 23 23"
    >
      <mask id="path-1-inside-1_1_35" fill="#fff">
        <rect
          width="15.472"
          height="15.472"
          x="0.713"
          y="0.236"
          rx="1.203"
        ></rect>
      </mask>
      <rect
        width="15.472"
        height="15.472"
        x="0.713"
        y="0.236"
        stroke="#fff"
        strokeWidth="3.985"
        mask="url(#path-1-inside-1_1_35)"
        rx="1.203"
      ></rect>
      <mask id="path-2-inside-2_1_35" fill="#fff">
        <rect
          width="15.472"
          height="15.472"
          x="7.159"
          y="6.682"
          rx="1.203"
        ></rect>
      </mask>
      <rect
        width="15.472"
        height="15.472"
        x="7.159"
        y="6.682"
        stroke="#fff"
        strokeWidth="3.985"
        mask="url(#path-2-inside-2_1_35)"
        rx="1.203"
      ></rect>
    </svg>
  );
};

export default Copy;
