import { toast } from 'react-toastify';
export default function handleCopyToClipboard(text) {
  // Copy the text inside the text field
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success('Text copied to clipboard!'); // Success toast
    })
    .catch((err) => {
      toast.error('Failed to copy text: ' + err); // Error toast
    });
}
