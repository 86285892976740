function Header() {
  return (
    <div className="flex flex-col items-center px-[55px]">
      <img
        alt="NEMOTRON"
        className="max-w-[280] mb-[12px] lg:max-w-[140px]"
        src="/images/logo.png"
      />
      <p className="text-white text-[18px] lg:text-[29px]">
        just a sentient clownfish trading shitters
      </p>
    </div>
  );
}

export default Header;
